.display-board-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.display-board-letter {
    padding: 1rem;
}

@media (max-width: 400px) {
    .display-board-letter {
        font-size: 1.2rem;
    }
}
