.reset-button {
    border: 2px solid green;
    border-radius: 3px;

    color: green;
}

.reset-button:hover {
    background-color: green;
    color: white;
    cursor: pointer;
}
